<template>
  <div class="">
    <div class="sctp-container">
      <div class="navigation">
        <div class="integrated-navigation" style="overflow: hidden;">
          <div class="top-marquee">
            <el-carousel height="500px">
              <el-carousel-item v-for="(item) in navigation.topMarquee" :key="item">
                <img class="background"
                     :src="item"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="sctp-stype">
            <div class="stype-item"
                 :key="sType.typeid + 'sType'"
                 v-for="sType in navigation.sTypeList"
                 @mouseenter="showSubSType(sType.typeid)"
                 @mouseleave="hideSubSType(sType.typeid)"
            >{{ sType.typename }}
            </div>
          </div>
          <div class="sctp-substype" v-show="navigation.showSubType" @mouseenter="showSubSType()"
               @mouseleave="hideSubSType()">
            <router-link
              v-for="subSType in navigation.subSTypeList"
              :key="subSType.subtypeid + 'subType'"
              v-show="subSType.showStatus || false"
              :to="'/goods-info/goods-info-hall?typeid=' + subSType.typeid + '&subtypeid=' + subSType.subtypeid"
            >
              <span class="substype-item">{{ subSType.subtypename }}</span>
            </router-link>
          </div>
          <div class="right-navigation">
            <div v-if="!user">
              <router-link :to="'/register'">
                <el-button type="primary" round="">{{ $t('message.registerAccount') }}</el-button>
              </router-link>
              <router-link :to="'/login'">
                <el-button type="warning" round="" style="margin-left: 22px;">{{
                    $t('message.userLogin')
                  }}
                </el-button>
              </router-link>
              <div data-v-xfcdfdpx4q="" style="font-size: 12px;margin: 10px;">
                <span>第三方登录</span>
                <img src="/images/login/wechat.png"
                     style="position: relative; top: 1px; left: 5px;width: 15px;height: 15px;"/>
              </div>
            </div>
            <div v-if="user" style="text-align: center; margin-bottom: 20px; color: #e3ae84;">
              <h3 style="color: #FFF; font-size: 30px;">welcome !</h3>
              <span>{{ user.usernice }}</span>
            </div>
            <div>
              <div
                style="background-color: rgba(255, 255, 255, 0.4); position: relative; margin: 0 -20px; padding: 2px; text-align: center; color: #F90;">
                平台统计指数
              </div>
              <div class="buy-info">
              </div>
              <div class="buy-info">
                <div class="buy-item">
                  <div class="middot"></div>
                  发布源码数 <span class="orange-color">{{ countInfo.countGoodsInfo }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  发布需求数 <span class="orange-color">{{ countInfo.countCodeRequire }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  需求浏览数 <span class="orange-color">{{ countInfo.sumCodeRequireHit }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  发布服务数 <span class="orange-color">{{ countInfo.countService }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  注册门户数 <span class="orange-color">{{ countInfo.countUser }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  在线交易数 <span class="orange-color">{{ countInfo.countDeal }}</span></div>
                <div class="buy-item">
                  <div class="middot"></div>
                  在线交易额 <span class="orange-color">{{ countInfo.sumDealAmount }}</span></div>
              </div>
            </div>
            <template v-if="systemNoticeConfig.data && systemNoticeConfig.data.length > 0">
              <div>
                <div
                  style="background-color: rgba(255, 255, 255, 0.4); position: relative; margin: 0 -20px; padding: 2px; text-align: center; color: #F90;">
                  公告
                </div>
                <template v-for="(notice, noticeIndex) in systemNoticeConfig.data">
                  <router-link :to="`/systemNotice/${notice.id}`">
                    <div class="pad-t5 sctp-white sctp-ellipsis">
                      {{ noticeIndex + 1 }}、{{ notice.title }}
                    </div>
                  </router-link>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="sctp-card" v-if="latestProduct && latestProduct.length > 0">
        <div class="sctp-card-header">
          <div class="sctp-card-title">热门源码</div>
          <div class="sctp-card-value" v-if="false">联系客服报名QQ：10254156</div>
        </div>
        <div class="sctp-card-body">
          <div class="goods-container">
            <el-row :gutter="10">
              <el-col :span="6"
                      :key="product.goodsid + 'latestProduct'"
                      v-for="product in latestProduct">
                <a target="_blank" :href="'/sourcecode/view/' + product.goodsid">
                  <div class="goods-card sctp-bg-white sctp-flex sctp-flex-column">
                    <el-image
                      lazy
                      style="width: 100%;height: 174px;"
                      :src="product.goodslogo" fit="cover"
                    ></el-image>
                    <div style="height: 40px;" class="sctp-ellipsis--l2 sctp-mar-tb5">
                      {{ product.goodsname }}
                    </div>
                    <div class="divider"></div>
                    <div class="flex flex-sb fz-12">
                      <div>
                        <span>{{ product.codeTypeStr }}</span>
                        <span class="sctp-mar-l10">({{
                            product.copyrightflag === 1 ? '有' : '无'
                          }}版权证书)</span>
                      </div>
                      <div>
                        <span>{{ getCity(product.cityCode) }}</span>
                      </div>
                    </div>
                    <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                      <div class="sctp-red">{{
                          product.saleprice ? `¥${product.saleprice}` : '免费'
                        }}
                      </div>
                      <div class="sctp-font-14">浏览数：{{ product.hits }}</div>
                    </div>
                  </div>
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="sctp-card" v-if="designList && designList.length > 0">
        <div class="sctp-card-header">
          <div class="sctp-card-title">热门设计</div>
          <div class="sctp-card-value"></div>
        </div>
        <div class="sctp-card-body">
          <div class="goods-container">
            <el-row :gutter="10">
              <template v-for="design in designList">
                <el-col :span="6">
                  <a :href="`/design/view/${design.designid}`" target="_blank">
                    <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                      <el-image
                        lazy
                        style="width: 100%;height: 200px;"
                        :src="design.designcoverImgurl" fit="cover"
                      ></el-image>
                      <div class="" style="padding: 0 8px 8px;">
                        <div class="sctp-ellipsis sctp-mar-tb5">{{ design.designname }}</div>
                        <div class="flex flex-sb fz-12 fc-info">
                          <div>
                            <span>{{ design.subTypeName }}</span>
                          </div>
                          <div>
                            <span>{{ getCity(design.cityCode) }}</span>
                          </div>
                        </div>
                        <div class="divider"></div>
                        <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                          <div class="sctp-red">{{
                              design.saleprice ? `¥${design.saleprice}` : '免费'
                            }}
                          </div>
                          <div class="">浏览数：{{ design.hits || 0 }}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </el-col>
              </template>
            </el-row>
          </div>
        </div>
      </div>
      <div class="sctp-card" v-if="documentList && documentList.length > 0">
        <div class="sctp-card-header">
          <div class="sctp-card-title">热门文档</div>
          <div class="sctp-card-value"></div>
        </div>
        <div class="sctp-card-body">
          <div class="goods-container">
            <el-row :gutter="10">
              <template v-for="document in documentList">
                <el-col :span="6">
                  <a :href="`/techdocs/view/${document.docid}`" target="_blank">
                    <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                      <el-image
                        lazy
                        style="width: 100%;height: 200px;"
                        :src="document.doccoverImgurl" fit="cover"
                      ></el-image>
                      <div class="" style="padding: 0 8px 8px;">
                        <div class="sctp-ellipsis sctp-mar-tb5">{{ document.docname }}</div>
                        <div class="flex flex-sb fz-12 fc-info">
                          <div>
                            <span>{{ document.subTypeName }}</span>
                          </div>
                          <div>
                            <span>{{ getCity(document.cityCode) }}</span>
                          </div>
                        </div>
                        <div class="divider"></div>
                        <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                          <div class="sctp-red">{{
                              document.saleprice ? `¥${document.saleprice}` : '免费'
                            }}
                          </div>
                          <div class="">浏览数：{{ document.hits || 0 }}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </el-col>
              </template>
            </el-row>
          </div>
        </div>
      </div>
      <div class="sctp-card" v-if="recommendService && recommendService.length > 0">
        <div class="sctp-card-header">
          <div class="sctp-card-title">推荐服务</div>
          <div class="sctp-card-value"></div>
        </div>
        <div class="sctp-card-body">
          <div class="goods-container">
            <el-row :gutter="10">
              <el-col :span="6"
                      :key="service.serviceid + 'recommendService'"
                      v-for="service in recommendService">
                <a target="_blank" :href="'/service/view/' + service.serviceid">
                  <div class="goods-card sctp-bg-white sctp-flex sctp-flex-column">
                    <el-image
                      lazy
                      style="width: 100%;height: 200px;"
                      :src="service.servicecovers && service.servicecovers[0]"
                      fit="cover"></el-image>
                    <div style="height: 44px;" class="sctp-ellipsis--l2 sctp-mar-tb5">
                      {{ service.servicename }}
                    </div>
                    <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                      <div class="sctp-red">¥{{ service.serviceprice || 0 }}</div>
                    </div>
                  </div>
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <template v-if="requireGroup">
        <div class="sctp-card">
          <div class="sctp-card-header">
            <div class="sctp-card-title">需求信息</div>
            <div class="sctp-card-value"></div>
          </div>
          <div class="sctp-card-body">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-row :gutter="10">
                  <template v-for="(require,key) in requireGroup">
                    <el-col :span="12" :key="key" class="sctp-mar-b10">
                      <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 200px;">
                        <div class="flex flex-sb">
                          <div class="fz-16 sctp-bold sctp-mar-b15">{{ key }}</div>
                          <router-link
                            :to="`/center/requirement?reqflag=${require[0].reqflag}`">
                            <div>More</div>
                          </router-link>
                        </div>
                        <div>
                          <template v-for="item in require">
                            <a target="_blank" :href="'requirement/view/' + item.requireid">
                              <div class="flex flex-sb sctp-flex-aic">
                                <div class="sctp-text-ellipsis pad-tb5 flex-item1">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.budget_scope ? `¥${item.budget_scope}` : '无预算' }}
                                </span>
                                  <span>{{ item.title }}</span>
                                </div>
                                <a style="width: 80px;"
                                   class="sctp-text-ellipsis sctp-tr">{{ item.hits }}次浏览</a>
                              </div>
                            </a>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </template>
                </el-row>
                <div class="sctp-card-header">
                  <div class="sctp-card-title">出售大厅</div>
                  <div class="sctp-card-value"></div>
                </div>
                <el-row :gutter="10">
                  <template>
                    <el-col :span="12"  class="sctp-mar-b10">
                      <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 200px;">
                        <div class="flex flex-sb">
                          <div class="fz-16 sctp-bold sctp-mar-b15">源码大厅</div>
                          <router-link
                            :to="`/center/sourcecode`">
                            <div>More</div>
                          </router-link>
                        </div>
                        <div>
                          <template v-for="item in goodsSourcecodeInfo">
                            <a target="_blank" :href="'sourcecode/view/' + item.goodsid">
                              <div class="flex flex-sb sctp-flex-aic">
                                <div class="sctp-text-ellipsis pad-tb5 flex-item1">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.saleprice ? `¥${item.saleprice}` : '无预算' }}
                                </span>
                                  <span>{{ item.goodsname }}</span>
                                </div>
                                <a style="width: 80px;"
                                   class="sctp-text-ellipsis sctp-tr">{{ item.hits }}次浏览</a>
                              </div>
                            </a>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </template>
                  <template>
                    <el-col :span="12"  class="sctp-mar-b10">
                      <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 200px;">
                        <div class="flex flex-sb">
                          <div class="fz-16 sctp-bold sctp-mar-b15">设计大厅</div>
                          <router-link
                            :to="`/center/design`">
                            <div>More</div>
                          </router-link>
                        </div>
                        <div>
                          <template v-for="item in GoodsDesignInfo">
                            <a target="_blank" :href="'design/view/' + item.designid">
                              <div class="flex flex-sb sctp-flex-aic">
                                <div class="sctp-text-ellipsis pad-tb5 flex-item1">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.saleprice ? `¥${item.saleprice}` : '无预算' }}
                                </span>
                                  <span>{{ item.designname }}</span>
                                </div>
                                <a style="width: 80px;"
                                   class="sctp-text-ellipsis sctp-tr">{{ item.hits }}次浏览</a>
                              </div>
                            </a>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </template>
                  <template>
                    <el-col :span="12"  class="sctp-mar-b10">
                      <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 200px;">
                        <div class="flex flex-sb">
                          <div class="fz-16 sctp-bold sctp-mar-b15">服务大厅</div>
                          <router-link
                            :to="`/center/techservice`">
                            <div>More</div>
                          </router-link>
                        </div>
                        <div>
                          <template v-for="item in goodsServiceInfo">
                            <a target="_blank" :href="'service/view/' + item.serviceid">
                              <div class="flex flex-sb sctp-flex-aic">
                                <div class="sctp-text-ellipsis pad-tb5 flex-item1">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.serviceprice ? `¥${item.serviceprice}` : '无预算' }}
                                </span>
                                  <span>{{ item.servicename }}</span>
                                </div>
                                <a style="width: 80px;"
                                   class="sctp-text-ellipsis sctp-tr">{{ item.hits }}次浏览</a>
                              </div>
                            </a>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </template>
                  <template>
                    <el-col :span="12"  class="sctp-mar-b10">
                      <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 200px;">
                        <div class="flex flex-sb">
                          <div class="fz-16 sctp-bold sctp-mar-b15">文档大厅</div>
                          <router-link
                            :to="`/center/techdocs`">
                            <div>More</div>
                          </router-link>
                        </div>
                        <div>
                          <template v-for="item in goodsDocsInfo">
                            <a target="_blank" :href="'techdocs/view/' + item.docid">
                              <div class="flex flex-sb sctp-flex-aic">
                                <div class="sctp-text-ellipsis pad-tb5 flex-item1">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.saleprice ? `¥${item.saleprice}` : '无预算' }}
                                </span>
                                  <span>{{ item.docname }}</span>
                                </div>
                                <a style="width: 80px;"
                                   class="sctp-text-ellipsis sctp-tr">{{ item.hits }}次浏览</a>
                              </div>
                            </a>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </template>
                </el-row>

              </el-col>
              <el-col :span="6">
                <div class="sctp-bg-white">
                  <div class="pad-tb15 pad-lr15 sctp-bg-white" style="height: 885px;">
                    <div class="flex flex-sb">
                      <div class="fz-16 sctp-bold sctp-mar-b15">最新发布需求</div>
                    </div>
                    <div>
                      <template v-for="(item, index) in latestRequire">
                        <a target="_blank" :href="'requirement/view/' + item.requireid">
                          <div class="sctp-text-ellipsis pad-tb10 flex-item1">
                            <div class="flex flex-sb sctp-mar-b10">
                                <span style="color: rgb(134, 134, 134); font-weight: bold;">{{
                                    item.requireType
                                  }} - {{
                                    item.codeTypeInfo.typename
                                  }}</span>
                            </div>
                            <div class="flex flex-sb">
                                <span class=""
                                      style="color: rgb(234, 2, 2);font-weight: bold;min-width: 100px;display: inline-block;">
                                  {{ item.budget_scope ? `¥${item.budget_scope}` : '无预算' }}
                                </span>
                              <span>{{ item.title }}</span>
                            </div>
                          </div>
                        </a>
                        <div v-if="index !== latestRequire.length - 1" class="sctp-line"
                             style="margin: 5px 0;"></div>
                      </template>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

        </div>
      </template>

      <div class="sctp-card" v-if="latestCodeRequire && latestCodeRequire.length">
        <div class="sctp-card-header">
          <div class="sctp-card-title">需求信息</div>
          <div class="sctp-card-value"></div>
        </div>
        <div class="sctp-card-body">
          <div class="goods-container">
            <el-row :gutter="10">
              <el-col :span="8"
                      :key="sctpCodeRequire.requireid + 'demand'"
                      v-for="sctpCodeRequire in 5">
                <a target="_blank" :href="'requirement/view/' + sctpCodeRequire.requireid">
                  <div class="goods-card sctp-bg-white sctp-flex sctp-flex-column">
                    <div class=" sctp-mar-tb5" style="color: rgb(160, 160, 160);">
                      <div class="sctp-flex sctp-flex-sb">
                        <span style="color: rgb(134, 134, 134); font-weight: bold;">{{
                            sctpCodeRequire.requireType
                          }} - {{
                            sctpCodeRequire.codeTypeInfo.typename
                          }}</span>
                        <a class="sctp-text-ellipsis" style="margin: 0 10px;text-align: right;"
                           href="#">浏览数：{{ sctpCodeRequire.hits }}</a>
                      </div>
                      <div class="sctp-line" style="margin: 5px 0;"></div>
                      <div class="sctp-text-ellipsis" style="height: 20px;">
                        <span style="color: rgb(234, 2, 2);font-weight: bold;">{{
                            sctpCodeRequire.budget_scope ? `¥${sctpCodeRequire.budget_scope}`
                              : '无预算'
                          }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>{{ sctpCodeRequire.title }}</span>
                      </div>
                      <div
                        style="height: 34px;color: rgb(156, 156, 156);font-size: 13px;line-height: 17px;margin: 5px 0;">
                        {{ subHtmlStrEllipsis(sctpCodeRequire.reqdesc) }}
                      </div>
                      <div class="sctp-flex sctp-flex-sb">
                        <span
                          class="">发布时间：{{
                            sctpCodeRequire.createtime && dateToString(
                              new Date(sctpCodeRequire.createtime), 'yyyy.MM.dd')
                          }}</span>
                        <span
                          style="white-space: nowrap;flex: 1;text-align: right;">地区：{{
                            sctpCodeRequire.userProvince || '未知'
                          }}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <index-right-side-tool></index-right-side-tool>
    <div class="links sctp-mar-t10">
      <div class="link-title">友情链接</div>
      <div class="link-content">
        <div class="link" v-for="item in $store.state.setting.friendLinks">
          <a :href="item.weblink" target="_blank">
            <div>{{ item.webname }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import area from "../resources/js/area";
import {common, home} from "@/apis/index";

const config = require('../resources/js/config');
export default {
  name: 'Login',
  components: {
    SctpCatalog: () => import('./home/HomeMenu'),
    IndexRightSideTool: () => import('../components/common/HomeToolbar')
  },
  data() {
    return {
      systemNoticeConfig: {
        data: []
      },
      countInfo: {
        countGoodsInfo: 0,
        countCodeRequire: 0,
        sumCodeRequireHit: 0,
        countService: 0,
        countUser: 0,
        countUserOnline: 0,
        countDeal: 0,
        sumDealAmount: 0
      },
      latestProduct: [],
      latestRequire: [],
      documentList: [],
      designList: [],
      requireGroup: null,
      recommendRequire: [],
      latestCodeRequire: [],
      recommendService: [],
      navigation: {
        showSubType: false,
        subSTypeList: [{
          typeid: null,
          subtypeid: null,
          subtypename: null,
          showStatus: false
        }],
        sTypeList: [{}],
        topMarquee: []
      },
      GoodsDesignInfo:"",
      goodsDocsInfo:"",
      goodsSourcecodeInfo:"",
      goodsServiceInfo:"",
    }
  },
  computed: {
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    }
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 0,
      title: '首页',
      type: 0
    });
  },
  beforeMount() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      home.homeData({
        type: 'adminweburl',
        page: 1,
        limit: 5
      }).then(res => {
        //首页商品推荐
        let {designList,documentList,goodsCodeList,serviceServices,recommendRequire,latestRequire,
          GoodsDesignInfo,goodsServiceInfo,goodsSourcecodeInfo,goodsDocsInfo} = res.data;
        this.designList = designList;
        this.documentList = documentList;
        this.latestProduct = goodsCodeList;
        this.recommendService = serviceServices;

        //需求大厅
        let requireGroup = {};
        recommendRequire.forEach(require => {
          if (requireGroup[require.requireType]) {
            requireGroup[require.requireType].push(require);
          } else {
            requireGroup[require.requireType] = [require];
          }
        });
        this.requireGroup = requireGroup;
        this.latestRequire = latestRequire;
        //首页商品
        for (const countInfoKey in this.countInfo) {
          this.countInfo[countInfoKey] = res.data[countInfoKey] || 0
        }
        //首页源码类型
        this.navigation.subSTypeList = res.data.sctpConfigStypeEntityList;
        const map = {};
        this.navigation.sTypeList = res.data.sctpConfigStypeEntityList.filter(subSType => {
          if (!map[subSType.typeid]) {
            map[subSType.typeid] = subSType.typeid;
            return subSType
          } else {
            return false
          }
        })

        //首页轮播图
        this.navigation.topMarquee = res.data.bannerEntities.map(item => item.imgurl)

        //出售大厅
        this.GoodsDesignInfo =GoodsDesignInfo
        this.goodsServiceInfo =goodsServiceInfo
        this.goodsSourcecodeInfo = goodsSourcecodeInfo
        this.goodsDocsInfo = goodsDocsInfo

        //首页公告
        let {data = []} = res.data.noticeSystemEntities;
        this.systemNoticeConfig.data = data;
      });
    },
    subHtmlStrEllipsis: function (text, length = 50) {
      const res = text && text.replace(/<img.*?>/g, '[图片]').replace(/<.*?>/g, '').replace(/\n/g,
        ' ').replace(/ +/g, ' ');
      return (res && text.length > 50 ? res.substr(0, length) + '...' : res)
    },
    showSubSType(typeid) {
      this.navigation.showSubType = true;
      if (typeid) {
        this.navigation.subSTypeList.forEach(subSTyle => {
          subSTyle.showStatus = subSTyle.typeid === typeid
        })
      }
    },
    hideSubSType() {
      this.navigation.showSubType = false
    },
  },
}
</script>

<style scoped lang="scss">
/* navigation */
.integrated-navigation .top-marquee {
  position: absolute;
  left: 0;
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.integrated-navigation img.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.integrated-navigation > * {
  z-index: 3;
  position: relative;
}

.integrated-navigation .sctp-stype .stype-item {
  padding: 14px 20px;
  color: #FFF;
  width: 150px;
}

.integrated-navigation .sctp-stype {
  float: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  height: 500px;
}

.integrated-navigation .sctp-stype .stype-item:hover {
  background: rgba(0, 0, 0, 0.5);
}

.integrated-navigation .sctp-substype {
  float: left;
  background-color: rgba(101, 101, 101, 0.5);
  width: 740px;
  height: 500px;
}

.integrated-navigation .sctp-substype span.substype-item {
  padding: 10px;
  display: inline-block;
  color: #FFF;
  font-size: 14px;
}

.integrated-navigation .right-navigation {
  float: right;
  height: 500px;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
  padding: 20px;
  width: 300px;
}

.el-button--primary {
  color: #FFF;
  background-color: #409EFF !important;
  border-color: #409EFF !important;
}

.count-info .count-item {
  float: left;
  width: 50%;
  padding: 5px;
}

.middot {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  display: inline-block;
  margin: 2px 5px;
  background-color: #F90;
}

.orange-color {
  color: #F90;
}

.buy-info .buy-item {
  margin: 10px;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
}

/* navigation */
.sctp-card {
  margin-top: 15px;
}

.sctp-card-header {
  padding: 10px 15px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sctp-card-title {
  font-size: 18px;
  color: #F90;
  font-weight: bold;
  padding-left: 10px;
  border-left: 3px solid #F90;
}

.sctp-card-value > .option-item {
  margin: 10px;
}

.sctp-line {
  border-bottom: 1px dashed #ccc;
}

.sctp-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sctp-card-body {
}

.goods-container /deep/ .el-col {
  margin-bottom: 15px;
}

.goods-container .goods-card {
  padding: 8px;
  cursor: pointer;
}

.links .link-title {
  font-size: 14px;
}

.links .link-content {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
}

.link-content .link {
  margin-left: 15px;
  margin-top: 10px;
}

.links {
  width: 1190px;
  background-color: white;
  padding: 10px 20px;
  margin: auto;
  margin-bottom: 10px;
}
</style>
